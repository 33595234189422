//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import 'moment-timezone';
import {formatarData} from '../../../utils/formatadorDataTabela'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import { useDispatch, useSelector, connect } from 'react-redux';
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import { CSVLink } from 'react-csv'
import decryptJWT from '../../../utils/decryptJWT'
import { formatarMilhar } from '../../../utils/formatarMilhar';

//COMPONENTES
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import NovoPedido from './novoPedido/novoPedido'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//ESTILIZAÇÃO
import '../../../css/painelPedidos/pedidosSimcard/tabela.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

//REDUX
import { revelarMostrarEmDispositivos, revelarExportarDetalhamento, 
    mudarManejador, limpouBusca, realizouBusca, desabilitarAcoes, desabilitarAcoesCliente, mudarFiltros, revelarBarraFiltragem, realizouFiltro,
    filtrosArrayBarra, recarregarBarraFiltragem, limpadorFiltros} from "../../../redux/actions/index"

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaPedidosSimcard(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [envioSelecionado, setEnvioSelecionado] = useState([])
    const [dadosParaDownload, setDadosParaDownload] = useState([])
    const [pedido, setPedido] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [n0_cliente_erp_antigo, setN0_cliente_erp_antigo] = useState(decryptJWT("codigoClienteErpAntigo"))
    const [cd_pav_cliente, setCd_pav_cliente] = useState(decryptJWT("codigoCliente"))
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    let itensVar = []

    //REDUX VARIÁVEIS
    const despacho = useDispatch();
    let filtros = useSelector(state => state.filtrosState.filtros);

    let revelarNovoPedidoSimcard =  useSelector(state => state.pedidosState.revelarNovoPedidoSimcard);
    let revelarExportarDetalhamentoItens =  useSelector(state => state.pedidosState.revelarExportarDetalhamento);
    let revelarMostrarEmDispositivo =  useSelector(state => state.pedidosState.revelarMostrarEmDispositivos);
    let recarregarBarraFiltragemValor = useSelector(state => state.filtrosState.recarregarBarraFiltragem)
    
    let temFiltros = false;
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(517) // 'pedidosSimcardTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(() => {
        if(props.revelarBarraFiltro === true){
            despacho(revelarBarraFiltragem(false))
        }
        if(props.filtros.vazio != 'vazio'){
            despacho(revelarBarraFiltragem(false))
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(limpadorFiltros(true))
        }
    }, [])
  
    
    //Acao Mostrar Em Dispositivo 
    useEffect(()=>{
        async function consultarItens(){
            let intenSting=[]
            await api.get('/m1/consultar/pedidos_itens_simcard?cd_dsp_pedido='+envioSelecionado.cd_dsp_pedido)
                .then(async function (response) {
                    if(response.data.status == 200){
                        await Promise.all(response.data.dados.map((item_consulta,i)=>{
                            intenSting.push(item_consulta.ds_iccid.trim())
                        }))
                        despacho(mudarFiltros({ICCID: intenSting}))
                        despacho(filtrosArrayBarra(['Itens pedido: '+pedido+'?ICCID']))
                        despacho(revelarBarraFiltragem(true))
                        despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor))
                        despacho(limpadorFiltros(false))
                        //aciona o event click para mudar de paguina
                        const link = document.getElementById('link-dispositivos')
                        link.click();
                    }
                })
                .catch(function (error) {
                    return error
                })
        }
        if(revelarMostrarEmDispositivo){
            consultarItens()
            despacho(limpadorFiltros(false))
            despacho(revelarMostrarEmDispositivos(false))
        }
    }, [revelarMostrarEmDispositivo])


    useEffect(() => {
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("pedidosSimcard"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/pedidos_simcard?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/pedidos_simcard", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/pedidos_simcard?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
    
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) { 
            apiData = await api.get('/m1/consultar/pedidos_simcard?contar=true&buscar='+conteudoBusca);
        }else{
            const dados = {
                n0_cliente_erp_antigo:n0_cliente_erp_antigo,
                cd_pav_cliente:cd_pav_cliente,
                paguinaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                contar:true,
                filtros: filtros
            }
            apiData = await api.post("/m1/filtrar/pedidos_simcard", dados);
        }
        itensVar = []
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }
     
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            setRenderizar(!renderizar)
            restaurarConfigsIniciaisPagina()
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    async function handlerConsultarIntensPedido(){
        await api.get('/m1/consultar/pedidos_itens_simcard?cd_dsp_pedido='+envioSelecionado.cd_dsp_pedido+
                                '&n0_documento='+envioSelecionado.n0_documento+
                                '&somenteUm=true')
            .then(async function (response) {
                if(response.data.status == 200){
                    Promise.all(await response.data.dados.map((item_dadosParaDownload,i)=>{
                        let vlrMensalidade = item_dadosParaDownload.n2_mensalidade;
                        let aux3 = parseInt(vlrMensalidade, 10);
                        aux3 = Number(vlrMensalidade);
                        aux3 = +vlrMensalidade;
                        aux3 = vlrMensalidade - 0;
                        aux3 = parseFloat(aux3).toFixed(2).replace('.',',')
                        dadosParaDownload.push({
                            Pedido:item_dadosParaDownload.ds_callerid,
                            Data:"'"+item_dadosParaDownload.ds_iccid+"'",
                            Tipo:item_dadosParaDownload.ds_dsp_operadora,
                            Quantidade:'R$ '+aux3,
                            Valor:item_dadosParaDownload.ds_dsp_plano,
                            "Valor de envio":'',
                            "Cód. de rastreamento":'',
                        })
                    }))
                }
            })
            .catch(function (error) {
            })
        //timeOut, pois e necessario um tempo para os dados serem setados
        setTimeout(() => {
            //aciona o event click para o ativar o download do arquivo
            const exportar = document.getElementById('exportar-detalhamento')
            exportar.click();
            //retirado os itens e deixado os dados da linha selecionada
            dadosParaDownload.splice(2,dadosParaDownload.length)
        }, 250);

        despacho(revelarExportarDetalhamento(false));
    }

    if(revelarExportarDetalhamentoItens){
        handlerConsultarIntensPedido()
    }

    const colunas = [
        {
            dataField: 'n0_documento',
            headerClasses: 'nao-selecionavel',
            text: 'Código',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'cd_dsp_pedido',
            headerClasses: 'nao-selecionavel',
            text: 'Código',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: "dt_emissao",
            headerClasses: 'nao-selecionavel',
            text: 'Data',
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell,true);
            }
        },
        {
            dataField: 'ds_operacao',
            headerClasses: 'nao-selecionavel',
            text: 'Tipo',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n0_quantidade_simcards',
            headerClasses: 'nao-selecionavel',
            text: 'Quantidade',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{formatarMilhar(cell)}</p>;
            }
        },
        {
            dataField: 'n2_valor_simcards',
            headerClasses: 'nao-selecionavel',
            text: 'Valor',
            sort: true,
            
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-pedidosSimcard"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedidosSimcard">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard"> 0.00 </p></> : <><p className="valor-rs-pedidosSimcard">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'n2_valor_ativacao',
            headerClasses: 'nao-selecionavel',
            text: 'Valor da ativação',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'n2_valor_frete',
            headerClasses: 'nao-selecionavel',
            text: 'Valor do envio',
            sort: true,
            formatter: (cell, row) => {
                const valor = parseFloat(cell);
                return <p className="fonte-cor-1 label-tabela-1 container-valor-pedidosSimcard"> {(!valor || isNaN(valor)) ? <><p className="valor-rs-pedidosSimcard2">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard2"> 0.00 </p></> : <><p className="valor-rs-pedidosSimcard2">R$</p> <p className="fonte-cor-1 valor-pedidosSimcard2"> {formatarMilhar(valor)}</p></>} </p>;
            }
        },
        {
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text: 'Usuário',
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'ds_codigo_rastreio',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. de rastreamento',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setEnvioSelecionado(row)
        }
    }
    
    
    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                let vlrVlr = row.n2_valor_frete;
                let aux1 = parseInt(vlrVlr, 10);
                aux1 = Number(vlrVlr);
                aux1 = +vlrVlr;
                aux1 = vlrVlr - 0;
                aux1 = parseFloat(aux1).toFixed(2).replace('.',',')

                let vlrEnvio = row.n2_valor_simcards;
                let aux2 = parseInt(vlrEnvio, 10);
                aux2 = Number(vlrEnvio);
                aux2 = +vlrEnvio;
                aux2 = vlrEnvio - 0;
                aux2 = parseFloat(aux2).toFixed(2).replace('.',',')
                setPedido(row.n0_documento)
                setDadosParaDownload([{
                    Pedido:row.cd_dsp_pedido,
                    Data:row.dt_emissao,
                    Tipo:row.ds_operacao,
                    Quantidade:row.n0_quantidade_simcards,
                    Valor:'R$ '+aux2,
                    "Valor de envio":'R$ '+aux1,
                    "Cód. de rastreamento":row.ds_codigo_rastreio,
                },
                {
                    Pedido:'Caller Id',
                    Data:'ICCID',
                    Tipo:'Operadora',
                    Quantidade:'Mensalidade',
                    Valor:'Plano',
                    "Valor de envio":'',
                    "Cód. de rastreamento":'',
                }])
                setEnvioSelecionado(row)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
         
        
            {revelarNovoPedidoSimcard === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovoPedido
                            reposicao={0}
                            setRenderizar={setRenderizar}
                            renderizar={renderizar}
                        >
                        </NovoPedido>
                    </div>
                </div>
            }
            
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"As Mensagens foram atualizadas."}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }

            <CSVLink id='exportar-detalhamento' filename={"Itens do Pedido "+pedido+".csv"} className="d-none" data={dadosParaDownload} separator={";"}/>
            <Link id='link-dispositivos' className="d-none" to={"/veye/dispositivos"}/>
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='n0_documento' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                selectRow={ selecaoLinhas }
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPedidosSimcard));