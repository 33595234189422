//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';
import {api, api_papeis} from '../../conexoes/api';/*eslint-disable*/
import { Card, Button, Form, ButtonToolbar, Row, Col, DropdownButton, Image } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import SweetAlert from 'react-bootstrap-sweetalert';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../utils/decryptJWT';
//ICONES
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight, faPlus} from '@fortawesome/free-solid-svg-icons';
import {lixeiraVermelho, editarVerde, elipseVermelho, elipseVerde} from '../../utils/alternanciaIcones';
//COMPONENTES
// import CadastroUsuario from './cadastro'
// import AlterarUsuario from './alterar'
import CadastroUsuario from './cadastro/cadastro'
import MensagemAtencao from '../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
// import 
import { func } from 'prop-types';
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados, mudarPermissaoElementoAcao, mudarManejador, limpadorFiltros, limpouBusca, desabilitarAcoesCliente, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, desabilitarAcoes, revelarExcluirUsuarioCliente, revelarReenviarLinkPrimeiroAcessoUsuarioCliente } from "../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../css/painelUsuarios/usuariosPainel.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []
function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [papeis, setPapeis] = useState([]);

    //HOOKS VARIÁVEIS
    const [cdCliente, setCdCliente] = useState('');
    const [dsCliente, setDsCliente] = useState('')
    const [cdPerfil, setCdPerfil] = useState('');
    const [dsPerfil, setDsPerfil] = useState('');
    const [cdUsuario, setCdUsuario] = useState('');
    const [dsUsuario, setDsUsuario] = useState('');
    const [dsEmail, setDsEmail] = useState('');
    const [dsTelefone, setDsTelefone] = useState('');
    const [dsSobrenomeUsuario, setDsSobrenomeUsuario] = useState('');
    const [flAtivo, setFlAtivo] = useState('');
    const [cdPessoa, setCdPessoa] = useState('');
    const [flCustomizado, setFlCustomizado] = useState('');
    const [dsCpf, setDsCpf] = useState('');
    const [dsSenha, setDsSenha] = useState('');
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENSAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [msgAtencao, setMsgAtencao] = useState(false)
    //HOOKS MODAL
    const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    const [cd_pav_cliente, setCd_pav_cliente] = useState(decryptJWT('codigoCliente'))
    const [cd_pav_usuario, setCd_pav_usuario] = useState(decryptJWT('codigo'))
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);

    let revelarCadastrarUsuario =  useSelector(state => state.virtueyesState.revelarCadastrarUsuario);
    let revelarEdicaoUsuario =  useSelector(state => state.virtueyesState.revelarEdicaoUsuario);
    let revelarClonarUsuario =  useSelector(state => state.virtueyesState.revelarClonarUsuario);
    let excluirUsuarioCliente =  useSelector(state => state.menuUsuarioState.revelarExcluirUsuarioCliente);
    let reenviarLinkPrimeiroAcessoUsuarioCliente =  useSelector(state => state.menuUsuarioState.revelarReenviarLinkPrimeiroAcessoUsuarioCliente);
    
    let permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)
    let temFiltros = false;

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(599) // 'painelUsuariosTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        restaurarConfigsIniciaisPagina()
        async function handlerVerificarPermissaoAcao(){
            despacho(mudarPermissaoElementoAcao( !await validadorPermissaoElemento(permissaoElemento,"veye/usuarios/acoes") ))
        }
        handlerVerificarPermissaoAcao()
        if(!filtros.vazio){
            despacho(revelarBarraFiltragem(false))
            despacho(mudarFiltros({vazio: 'vazio'}))
            despacho(limpadorFiltros(true))
        }
    }, [])

    if(excluirUsuarioCliente){
        handleMensagemAtencao(null, false)
        despacho(revelarExcluirUsuarioCliente(false)) 

        setMsgAtencao({
            handlerConfirmarAtencao: handlerDeletarUsuario,
            titulo: "Deletar "+dsUsuario,
            paragrafo1: "Registros deletados não poderão ser recuperados.",
        })
    }

    if(reenviarLinkPrimeiroAcessoUsuarioCliente){
        handleMensagemAtencao(null, false)
        despacho(revelarReenviarLinkPrimeiroAcessoUsuarioCliente(false)) 

        setMsgAtencao({
            handlerConfirmarAtencao: () => handlerReenviarLink(dsEmail),
            titulo: "Deseja reenviar o link de primeiro acesso?",
            paragrafo1: `O usuário ${dsUsuario} receberá um e-mail com link para cadastrar senha.`,
        })
    }

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("usuarioCliente"))
        if(!isSelect){
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(true));
        }
        async function carregarTabela(){
             //clicou no botão de limpar a busca geral e retorna tudo ao estado original
             if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }   

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }   
             

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/usuario?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+encodeURIComponent(conteudoBusca));
                    inserirData(data.dados)
                    const { paper_data } = await api_papeis.get('/api/v2/papers/read/group')
                    setPapeis(paper_data)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    cd_pav_usuario_execao:true,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/usuario", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/usuario?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
        if (contador === i){
            let k = i
            for(let j=0;j<data.length;j++){
            itensVar[k] = data[j]
            k++
            }
        }
        if(i == (totalItens-1)){
            setItens(JSON.parse(JSON.stringify(itensVar)))
        }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/usuario?contar="true"&buscar='+encodeURIComponent(conteudoBusca));
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    cd_pav_usuario_execao:true,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/usuario", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'ds_pav_usuario',
        text: 'Nome',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_usuario_sobrenome',
        text: 'Sobrenome',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_email',
        text: 'E-mail',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_telefone',
        text: 'Telefone',
        sort: true,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    // {
    //     dataField: 'ds_pav_perfil',
    //     text: 'Perfil',
    //     sort: true,
    //     headerClasses: 'nao-selecionavel',
    //     formatter: (cell, row) => {
    //         return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
    //     }
    // },
    {
        dataField: 'fl_ativo',
        headerClasses: 'nao-selecionavel',
        text: 'Ativo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell || cell == 0 ? 
                    cell == 1 ?
                        <>
                        <Image className="img-eclipse-status" src={elipseVerde()}/>
                        </>
                    :
                    <>
                        <Image className="img-eclipse-status" src={elipseVermelho()}/>
                    </> 
                :''}
            </p>;
        }
    },]
    
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setCdCliente(row.cd_pav_cliente);
            setCdPerfil(row.cd_pav_perfil);
            setDsPerfil(row.ds_pav_perfil);
            setCdUsuario(row.cd_pav_usuario);
            setDsUsuario(row.ds_pav_usuario);
            setDsSobrenomeUsuario(row.ds_pav_usuario_sobrenome);
            setDsTelefone(row.ds_telefone);
            setDsEmail(row.ds_email);
            setFlAtivo(row.fl_ativo);
            setCdPessoa(row.cd_pav_pessoa);
            setFlCustomizado(row.fl_customizado);
            setDsCpf(row.ds_cpf);
            setDsSenha(row.ds_senha);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdCliente(row.cd_pav_cliente);
                setCdPerfil(row.cd_pav_perfil);
                setDsPerfil(row.ds_pav_perfil);
                setCdUsuario(row.cd_pav_usuario);
                setDsUsuario(row.ds_pav_usuario);
                setDsSobrenomeUsuario(row.ds_pav_usuario_sobrenome);
                setDsTelefone(row.ds_telefone);
                setDsEmail(row.ds_email);
                setFlAtivo(row.fl_ativo);
                setFlCustomizado(row.fl_customizado)
                setCdPessoa(row.cd_pav_pessoa)
                setDsCpf(row.ds_cpf);
                setDsSenha(row.ds_senha);
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    async function handlerDeletarUsuario(){
        ColetaClickstream(605) // 'painelUsuariosTabelaDropAcoesExcluirDeletar'
        await api.get('/m1/deletar/usuario?cd_pav_usuario='+cdUsuario+"&cd_pav_usuario_deleted="+decryptJWT("codigo"))
        .then((response)=>{
            if(response.status != 200){
                setMensagemErro("Ocorreu um erro na deleção de usuario")
                handleMensagemErro(false)
            }
            else{
                handleMensagemSucesso(false)
            }
        })
        .catch(()=>{
            setMensagemErro("Ocorreu um erro na deleção de usuario")
            handleMensagemErro(false)
        })
        setItens([])
        itensVar =[]
        setRenderizar(!renderizar)
    }

    async function handlerReenviarLink(emailReenvio) {
        api.get(`/m1/servicos/token/email_primeiro_acesso?ds_email=${emailReenvio.trim()}`)
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemAtencao(handleConfirmarAtencao, confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleConfirmarAtencao();
            } else if(msgAtencao?.titulo.includes('Deletar') || excluirUsuarioCliente) {
                ColetaClickstream(1265) // 'painelUsuariosTabelaDropAcoesExcluirCancelar'
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                setRenderizar(!renderizar)
            }
        }
        setRenderizar(!renderizar)
    }
    return(
        <>
        {visualizarDados === true &&
            <BarraDadosCliente/>
        }
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { revelarCadastrarUsuario &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroUsuario
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroUsuario>
                    </div>
                </div>
            }
            { revelarEdicaoUsuario &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroUsuario
                            tituloJanela={'Editar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdUsuario={cdUsuario}
                            dsNome={dsUsuario}
                            dsSobrenome={dsSobrenomeUsuario}
                            dsCpf={dsCpf}
                            dsTelefone={dsTelefone}
                            dsEmail={dsEmail}
                            flAtivo={flAtivo}
                            dsSenha={dsSenha}
                            cdPerfil={cdPerfil}
                            dsPerfil={dsPerfil}
                            cdPessoa={cdPessoa}
                        >
                        </CadastroUsuario>
                    </div>
                </div>
            }
            { revelarClonarUsuario &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroUsuario
                            tituloJanela={'Clonar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdUsuario={cdUsuario}
                            dsNomeOriginal={dsUsuario}
                        >
                        </CadastroUsuario>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_usuario' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                    Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Usuario "+ dsUsuario +" deletado."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
            { revelarMensagemAtencao &&
                <MensagemAtencao
                    handleMensagemAtencao={(v) => handleMensagemAtencao(msgAtencao.handlerConfirmarAtencao, v)}
                    mensagemTitulo={msgAtencao.titulo}
                    mensagemParagrafo1={msgAtencao.paragrafo1}
                    mensagemBotao='Confirmar'
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));