//BIBLIOTECAS
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import { Form, Col, Button, ButtonToolbar } from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import {api} from '../../../../../conexoes/api'
import {formatarData} from '../../../../../utils/formatadorDataTabela'
import decryptJWT from '../../../../../utils/decryptJWT'
import {validadorPermissaoElemento} from '../../../../../utils/verificadorPermissaoElemento'
import {colunasFaturas,colunasSimcard} from '../../../../../utils/colunas'
//ICONES
import {elipseVerde, editarVerde, lixeiraVermelho, envelopeFechado} from "../../../../../utils/alternanciaIcones";
//MENSAGENS
import MensagemErro from '../../../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../../../componentesModais/mensagens/mensagemSucesso'
// SPINNER
import SpinerVeye from '../../../../../components/spinnerVeye/spinnerVeye.js'
//REDUX
import { useDispatch,useSelector } from 'react-redux';
import {
    revelarAlterarAdicionarLogin, revelarCadastrarUsuario,
    visualizarDadosRefresh
} from "../../../../../redux/actions/index";

import AlterarLogin from '../../../../painelVirtueyes/alterarLogin/alterarLogin'
import { useDropzone } from 'react-dropzone';
//CSS
import './informacoesLogin.css'
import '../../../../../../src/css/painelUsuarios/usuariosPainel.css'
import '../../../../../../src/css/painelUsuarios/estilo.css'
//COMPONENTES
import CadastroUsuario from "../../../../painelUsuarios/cadastro/cadastro";
//MUI
import MensagemAtencao from "../../../../componentesModais/mensagens/mensagemAtencao";
import ColetaClickstream from "../../../../clickstreamAnalise/clickstreamColeta";





export default function InformacoesLogin(props) {
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);
    //HOOKS
    const [itens, setItens] = useState([])
    const [telaTipo, setTipoTela] = useState('cadastro')
    const [renderizar, setRenderizar] = useState(false)

    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [msgAtencao, setMsgAtencao] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro. Por favor tente novamente mais tarde.")
    const [mensagemSucesso, setMensagemSucesso] = useState("A ação foi realizada com sucesso.")
    const [mensagemErro2, setMensagemErro2] = useState("")

    const [nome, setNome] = useState()
    const [sobrenome, setSobrenome] = useState()
    const [cpf, setCpf] = useState()
    const [cnpj, setCnpj] = useState()
    const [email, setEmail] = useState()
    const [telefone, setTelefone] = useState()
    const [cdPerfil, setCdPerfil] = useState()
    const [dsPerfil, setDsPerfil] = useState()
    const [cdCliente, setCdCliente] = useState(props.cdCliente)
    const [cdUsuario, setCdUsuario] = useState()
    const [envioSelecionado, setEnvioSelecionado] = useState()
    const [carregarUsuario, setCarregarUsuario] = useState(true)
    const [permissaoCarregarUsuario, setPermissaoCarregarUsuario] = useState()
    const [ revelarModalUsuario, setRevelarModalUsuario ] = useState(false);

    const despacho = useDispatch();
    const reduxRevelarAlterarAdicionarLogin =  useSelector(state => state.virtueyesState.revelarAlterarAdicionarLogin);
    const visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);



    useEffect(() => {
        setSpinAtivo(true)
        async function handleCarregarInformacoesLogin(){
            const data = await api.get('m1/consultar/usuario/painel_virtueyes_clientes_usuarios?cd_pav_cliente='+props.cdCliente);
            if(data.data.dados != undefined){
                setItens(data.data.dados)
            }
        }
        handleCarregarInformacoesLogin();
        setSpinAtivo(false)
    }, [renderizar])

    function handlerDelete(data) {
        const ds_pav_usuario = data.ds_pav_usuario;
        const cdUsuario = data.cd_pav_usuario;
        setRevelarMensagemAtencao(true);

        setMsgAtencao({
            handlerConfirmarAtencao: () => handleDeletarUsuario({nome: ds_pav_usuario, cdUsuario}),
            titulo: "Deletar "+ds_pav_usuario,
            paragrafo1: "Registros deletados não poderão ser recuperados.",
        })
    }

    async function handleDeletarUsuario({nome, cdUsuario}) {
        ColetaClickstream(605) // 'painelUsuariosTabelaDropAcoesExcluirDeletar'
        
        await api.get('/m1/deletar/usuario?cd_pav_usuario=' + cdUsuario + "&cd_pav_usuario_deleted=" + decryptJWT("codigo"))
            .then((response) => {
                if (response.status != 200) {
                    setMensagemErro("Ocorreu um erro na deleção de usuario")
                    handleMensagemErro(false)
                } else {
                    setMensagemSucesso('O usuário ' + nome + ' foi deletado com sucesso.');
                    handleMensagemSucesso(false)
                }
            })
            .catch(() => {
                setMensagemErro("Ocorreu um erro na deleção de usuario")
                handleMensagemErro(false)
            })
        setRenderizar(!renderizar);
    }

    function handlerReenviar(data) {
        setRevelarMensagemAtencao(true);

        setMsgAtencao({
            handlerConfirmarAtencao: () => handlerReenviarLink(data.ds_email),
            titulo: "Deseja reenviar o link de primeiro acesso?",
            paragrafo1: `O usuário ${data.ds_pav_usuario} receberá um e-mail com link para cadastrar senha.`,
        })
    }

    async function handlerReenviarLink(emailReenvio) {
        api.get(`/m1/servicos/token/email_primeiro_acesso?ds_email=${emailReenvio.trim()}`)
        //     .then((response) => {
        //         if (response.status != 200) {
                    // setMensagemErro("Ocorreu um erro ao reenviar link")
                    // handleMensagemErro(false)
        //         } else {
                    // setMensagemSucesso('O link de primeiro acesso será reenviado por e-mail');
                    // handleMensagemSucesso(false)
        //         }
        //     })
        //     .catch(() => {
        //         setMensagemErro("Ocorreu um erro ao reenviar link")
        //         handleMensagemErro(false)
        //     })
        // setRenderizar(!renderizar);
    }



    let colunas = [{
        dataField: 'cd_pav_usuario',
        text: 'Cód. Usuário',
        headerClasses: 'nao-selecionavel',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo ">{cell}</p>;
        }
    },
        {
            dataField: 'ds_pav_usuario',
            text: 'Nome',
            headerClasses: 'nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo">
                    <img className="informacoes-login-usuario-img" src={row.bl_imagem != null ? row.bl_imagem : "https://jazzaero.com.br/wp-content/uploads/2017/05/default-placeholder-profile-icon-avatar-gray-woman-90197997.jpg"} alt="perfil" />{cell}
                </p>;
            }
        },
        {
            dataField: 'ds_email',
            text: 'E-mail',
            headerClasses: 'nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo ">{cell}</p>;
            }
        },
        {
            dataField: 'ds_telefone',
            text: 'Telefone',
            headerClasses: 'nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo ">{cell}</p>;
            }
        },
        {
            dataField: 'ds_pav_perfil',
            text: 'Perfil',
            headerClasses: 'nao-selecionavel',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo ">{cell}</p>;
            }
        },
        {
            dataField: 'ds_cliente_texto',
            text: 'Cliente',
            headerClasses: 'nao-selecionavel',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-detalhamento-cliente-1-novo ">{cell}</p>;
            }
        },
        {
            dataField: 'ultimo_acesso',
            text: 'Último acesso',
            headerClasses: 'nao-selecionavel',
            hidden: true,
            sort: true,
            formatter: (cell, row) => {
                if(cell){
                    cell = cell.replace('T', ' ').split('.')
                    if(cell.length > 0){
                        cell = cell[0]
                    }
                }
                return formatarData(cell);
            }
        },
        {
            dataField: 'fl_ativo',
            text: 'Status',
            headerClasses: 'nao-selecionavel',
            sort: true,
            formatter: (cell, row) => {
                return (
                    <div className="label-tabela-1">
                        {row.fl_ativo === 1 ?
                            <p className="status-detalhemento-cliente fonte-cor-2">Ativo</p>
                            :
                            <p className="status-detalhemento-cliente fonte-cor-3">Desativado</p>
                        }
                    </div>
                )
            }
        },
        {
            dataField: '',
            text: 'Ações',
            headerClasses: 'nao-selecionavel',
            classes: 'nao-selecionavel',
            formatter: (cell, row) => {
                return (
                    <ButtonToolbar>
                        <Button variant="info" className="botoes-tabela-acoes" size="sm"
                                onClick={() =>  handlerReenviar(row)}>
                            <img className="icone-botao-acao-tabela" src={envelopeFechado()} alt="reenviar link de primeiro acesso"/>
                        </Button>
                        <Button variant="info" className="botoes-tabela-acoes" size="sm"
                                onClick={() =>  handleAdicionarUsuario('editar', row)}>
                            <img className="icone-botao-acao-tabela" src={editarVerde()} alt="editar usuário"/>
                        </Button>
                        <Button variant="info" className="botoes-tabela-acoes" size="sm"
                                onClick={() =>  handlerDelete(row)}>
                            <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="excluir usuário"/>
                        </Button>
                    </ButtonToolbar>


                )
            }
        }]

    function disableEditMode() {
        return
    }
    function handleAbrirEdicaoUsuario(){
        despacho(revelarAlterarAdicionarLogin(true))
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNome(row.ds_pav_usuario)
            setSobrenome(row.ds_pav_usuario_sobrenome)
            setEmail(row.ds_email)
            setTelefone(row.ds_telefone)
            setCdPerfil(row.cd_pav_perfil)
            setDsPerfil(row.ds_pav_perfil)
            setCdUsuario(row.cd_pav_usuario)
            setCpf(row.ds_cpf)
            setCnpj(row.ds_cnpj)
        }
    }



    const selecaoLinhas = {
        // mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setCarregarUsuario(false)
                setEnvioSelecionado(row)
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label style={{display: 'none'}} className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    useEffect(()=>{
        async function handlerPermissoes(){
            setPermissaoCarregarUsuario(await validadorPermissaoElemento(permissaoElemento,"veye/virtueyes/clientes/carregarUsuaruio"))
        }
        handlerPermissoes()
    },[])

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true);
        } else {
            setRevelarMensagemSucesso(false);
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar));
        }
    }

    function handleMensagemAtencao(handlerConfirmarAtencao, confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handlerConfirmarAtencao()
            }
            else if(msgAtencao?.titulo.includes('Deletar')) {
                ColetaClickstream(1265) // 'painelUsuariosTabelaDropAcoesExcluirCancelar'
            }
        }
    }

    function handleAdicionarUsuario(acao, data) {
        setTipoTela(acao)
        setEnvioSelecionado(data);
        setRevelarModalUsuario(true)
    }

    return(

        <div className="container-informacoes-login">


            {revelarModalUsuario == true &&
                <>
                {telaTipo == 'cadastro'
                        ?
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <CadastroUsuario
                                    tituloJanela={'Cadastrar'}
                                    cdClienteN3={cdCliente}
                                    cdUsuarioN3={cdUsuario}
                                    cadastroN3={true}
                                    tipoN3={props.cdTipoCliente}
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    setRevelarModalUsuario={setRevelarModalUsuario}
                                >
                                </CadastroUsuario>
                            </div>
                        </div>
                        :
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <CadastroUsuario
                                    cadastroN3={true}
                                    tipoN3={props.cdTipoCliente}
                                    cdClienteN3={cdCliente}
                                    cdUsuarioN3={cdUsuario}
                                    tituloJanela={'Editar'}
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    setRevelarModalUsuario={setRevelarModalUsuario}
                                    dsNome={envioSelecionado.ds_pav_usuario}
                                    dsSobrenome={envioSelecionado.ds_pav_usuario_sobrenome}
                                    dsCpf={cpf}
                                    dsTelefone={envioSelecionado.ds_telefone}
                                    dsEmail={envioSelecionado.ds_email}
                                    flAtivo={envioSelecionado.fl_ativo}
                                    dsSenha={''}
                                    cdPerfil={cdPerfil}
                                    dsPerfil={dsPerfil}
                                    cdUsuario={envioSelecionado.cd_pav_usuario}
                                    cdPessoa={envioSelecionado.cd_pav_pessoa}
                                ></CadastroUsuario>
                            </div>
                        </div>
                }
                </>
            }

            {reduxRevelarAlterarAdicionarLogin === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <AlterarLogin
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            codigo_cliente={props.noCdCliente}
                            nome={nome}
                            sobrenome={sobrenome}
                            email={email}
                            telefone={telefone}
                            cdPerfil={cdPerfil}
                            dsPerfil={dsPerfil}
                            cdUsuario={cdUsuario}
                            cpf={cpf}
                            cnpj={cnpj}
                        />
                    </div>
                </div>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>}
            <div className="container-informacoes-login-telas">
                <div className="cliente-container-informacoes-login-resumo fundo-cor-7">
                    <Form.Row>
                        <Col>
                            <div className={'header-info-login'}>
                                <Button className="botao-confirmar" size="sm" onClick={() => handleAdicionarUsuario('cadastro')}>
                                    <div className="fonte-cor-4">Criar usuário</div>
                                </Button>
                            </div>

                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col>
                            <div className="container-tabela-informacoes-login-usuarios">
                                <BootstrapTable
                                    hover
                                    striped={true}
                                    classes='tabela-transparente-header-fixo-novo padding-wrapper table-striped'
                                    keyField='cd_pav_usuario'
                                    data={ itens }
                                    columns={ colunas }
                                    rowEvents={eventosLinhas}
                                    selectRow={ selecaoLinhas }
                                    noDataIndication="Nenhum item encontrado"
                                    bootstrap4 = {true}
                                />
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </div>
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={mensagemErro}
                            mensagemParagrafo2={mensagemErro2}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarMensagemSucesso && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            )}
            { revelarMensagemAtencao &&
                <MensagemAtencao
                    handleMensagemAtencao={(v) => handleMensagemAtencao(msgAtencao.handlerConfirmarAtencao, v)}
                    mensagemTitulo={msgAtencao.titulo}
                    mensagemParagrafo1={msgAtencao.paragrafo1}
                    mensagemBotao='Confirmar'
                />
            }
        </div>
    )
}