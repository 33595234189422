import styled from "styled-components";
import Switch from "@mui/material/Switch";
import Snackbar from "@mui/material/Snackbar";

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.cor38,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.cor38,
  },
}));

export const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  ".MuiSnackbarContent-root": {
    backgroundColor: theme.cor1,
    color: theme.cor65,
    marginBottom: "20px",
  },
}));

export const Overalay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = styled.div`
  position: fixed;
  margin: auto auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 645px;
  border-radius: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.cor23}!important;
`;

export const TituloWrapper = styled.div`
  display: grid;
  grid-template-columns: 14px 1fr auto;
  align-items: center;
  gap: 16px;
`;

export const TituloModal = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  color: ${({ theme }) => theme.cor49};
`;

export const BotaoFechar = styled.button`
  height: 2.1875em;
  width: 2.1875em;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none !important;
`;

export const Elipse = styled.img`
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.cor11};
  margin-top: 2px;
`;

export const GrupoInput = styled.div`
  display: grid;
  gap: 16px;
`;

export const InputLabel = styled.label`
  color: ${({ theme }) => theme.cor49};
  font-size: 1.25em;
  margin: 0;
`;

export const Form = styled.form`
  margin-top: 24px;
  display: grid;
  gap: 24px;
  grid-auto-columns: 100%;
`;

export const ClienteSelecionado = styled.span`
  height: 40px;
  width: 100%;
  font-family: RobotoRegular;
  line-height: 1em;
  border: 0em;
  padding: 8px 12px;
  box-shadow: none;
  border-radius: 5em;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.cor9};
  background-color: ${({ theme }) => theme.cor27};
  align-items: center;
  color: ${({ theme }) => theme.cor9};
  font-family: RobotoRegular;
  font-size: 1.125em;
`;

export const SetaDropdown = styled.img`
  transform: rotate(360deg);
`;

export const Linha = styled.div`
  display: grid;
  grid-template-columns: ${({ linhaEmail }) =>
    linhaEmail ? "1fr auto" : "1fr 1fr"};
  gap: 24px;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: 1.0625em;
  outline: none;
  color: ${({ theme }) => theme.cor49};
  height: 40px;
  width: 100%;
  font-family: RobotoRegular;
  box-shadow: none;
  border-radius: 5em;
  color: ${({ theme }) => theme.cor9};
  background-color: ${({ theme }) => theme.cor27};
  padding: 8px 12px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  border-radius: 24px;
  padding: 8px 12px;
  color: ${({ theme }) => theme.cor49};
  background-color: ${({ theme }) => theme.cor27};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BotaoVerSenha = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  outline: none !important;
`;

export const BotaoGerarSenha = styled.button`
  gap: 8px;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  align-self: end;
  border: none;
  border-radius: 24px;
  height: 40px;
  color: ${({ theme }) => theme.cor38};
  background-color: ${({ theme }) => theme.cor64};
  box-shadow: 0px 3px 1px -2px #00000033;
  outline: none !important;
  text-transform: uppercase;
`;

export const Aviso = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.cor9};
`;

export const Divisor = styled.div`
  background-color: ${({ theme }) => theme.cor61};
  height: 1px;
`;

export const BotoesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 24px;
  justify-content: end;
`;

export const BotaoConfirmar = styled.button`
  background-color: ${({ theme }) => theme.cor11};
  border: none;
  font-family: RobotoBold;
  font-size: 1em;
  font-weight: 700;
  height: 2.625em;
  min-width: 8.75em;
  padding: 0 1.875em;
  box-shadow: none;
  border-radius: 3.125em;
  outline: none;
  color: ${({ theme }) => theme.cor1};

  :disabled {
    background-color: ${({ theme }) => theme.cor61};
    color: ${({ theme }) => theme.cor62};
  }
`;

export const BotaoCancelar = styled.button`
  font-family: RobotoBold;
  font-size: 1em;
  font-weight: 700;
  height: 2.625em;
  min-width: 8em;
  padding: 0 1.875em;
  border: 0.09375em solid ${({ theme }) => theme.cor11};
  color: ${({ theme }) => theme.cor11};
  background-color: transparent;
  box-shadow: none;
  border-radius: 3.125em;
  outline: none !important;
`;

export const MensagemWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
