//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import {Image, Button} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
//IMPORTAÇÕES ICONES
import { setaDropdown } from "../../../utils/alternanciaIcones";
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from "react-redux";
import { revelarDoppelgangerCliente, visualizarDadosRefresh, mudarDoppleGangerCliente, mudarManejador, desabilitarAcoes, desabilitarAcoesCliente, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro,
} from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import DetalhamentoCliente from '../detalhamentoCliente/detalhamentoCliente'
import HistoricoAcessos from '../historicoAcessos/historicoAcessos'
import AdicionarLogin from '../adicionarLogin/adicionarLogin'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import ConsultarSimcard from '../consultarSimcard/consultarSimcard'
import CadastroCliente from '../cliente/cadastro/cadastro'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import CadastroUsuario from '../usuario/cadastro/cadastro'
import decryptJWT from '../../../utils/decryptJWT'
//ESTILIZAÇÃO
import '../../../css/painelVirtueyes/virtuTabelaClientes/tabelaCliente.css'
// SPINNER
import SpinerVeye from "../../../components/spinnerVeye/spinnerVeye.js";
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0;
let controladorPagina = 1;
let totalPaginas = 0;
let totalItens = 0;
let itensVar = [];

function TabelaClientesVirtueyes(props) {
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50); 
    const [noCdCliente, setNoCdCliente] = useState(-1);
    const [cdCliente, setCdCliente] = useState(-1);
    const [dsCliente, setDsCliente] = useState('NULL');
    const [logradourocliente, setLogradouroCliente] = useState()
    const [bairrocliente, setBairroCliente] = useState()
    const [ufcliente, setUfCliente] = useState()
    const [cidadecliente, setCidadeCliente] = useState()
    const [cepcliente, setCepCliente] = useState()
    const [vendedorcliente, setVendedorCliente] = useState()
    const [cpfVendedorCliente, setCpfVendedorCliente] = useState()
    const [emailVendedorCliente, setEmailVendedorCliente] = useState()
    const [telefoneVendedorCliente, setTelefoneVendedorCliente] = useState()
    // const [clienteRelacionamento, setClienteRelacionamento] = useState(1)
    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [clienteNivel, setClienteNivel] = useState()
    
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let revelarHistoricoAcessos =  useSelector(state => state.virtueyesState.revelarHistoricoAcessos);
    let revelarAdicionarLogin =  useSelector(state => state.virtueyesState.revelarAdicionarLogin);
    let revelarConsultarSimcard =  useSelector(state => state.virtueyesState.revelarConsultarSimcard);
    let revelarCadastroCliente =  useSelector(state => state.virtueyesState.revelarCadastroCliente);
    let revelarDoppelgangerClienteVar =  useSelector(state => state.virtueyesState.revelarDoppelgangerCliente);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarCadastrarUsuario = useSelector(state => state.virtueyesState.revelarCadastrarUsuario);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);

    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    const despacho = useDispatch();
    const cdClienteErp = decryptJWT("clientePrimario");

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(375) // 'virtuTabelaClientesTabelaClientes'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        despacho(revelarBarraFiltragem(false));
        if (!filtros.vazio) {
            despacho(mudarFiltros({ vazio: "vazio" }));
        }
    }, []);

    useEffect(() => {
        setSpinAtivo(true);
        despacho(mudarManejador("virtueyesClientes"));
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        async function carregarTabela(){
            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if (limpouBuscaGeral) {
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina();
            }

            //clicou no botão de busca geral
            if (realizouBuscaGeral) {
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina();
            }

            //clicou no botão para filtro
            if (realizouFiltragem) {
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina();
            }

            if (conteudoBusca && filtros.vazio) {
                if (totalPaginas == 0) {
                    contador = 0;
                    itensVar = [];
                    setItens([]);
                    await contarItens();
                } else {
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get(
                        "/m1/consultar/cliente?" +
                            "paginaAtual=" +
                            contador +
                            "&painel_virtueyes=true"+
                            "&carregarLimit=" +
                            valorLimite +
                            "&buscar=" +
                            encodeURIComponent(conteudoBusca) + 
                            "&separadorLinha="+separadorLinha
                    );
                    inserirData(data.dados.filter(c => [0, 1, 2].includes(parseInt(c.n0_nivel))));
                }
            } else if (!filtros.vazio) {
                if (totalPaginas == 0) {
                    contador = 0;
                    itensVar = [];
                    setItens([]);
                    await contarItens();
                } else {
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    painel_virtueyes:true,
                    filtros: filtros,
                };
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post(
                        "/m1/filtrar/cliente",
                        dados
                    );
                    inserirData(data.dados);
                }
            } else {
                if (totalPaginas == 0) {
                    await contarItens();
                } else {
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get(
                        "/m1/consultar/cliente?" +
                            "paginaAtual=" +
                            contador +
                            "&painel_virtueyes=true"+
                            "&carregarLimit=" +
                            valorLimite
                    );
                    inserirData(data.dados.filter(c => [0, 1, 2].includes(parseInt(c.n0_nivel))));
                }
            }
            setSpinAtivo(false);
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros]);

    useEffect(() => {
        if (cdCliente > 0 && revelarDoppelgangerClienteVar === true) {
            async function handleDoppelgangerCliente() {
                setSpinAtivo(true);
                // let fatContas = [];
                // const contasUsuario = await api.get(
                //     "m1/consultar/fat_conta/contas_usuario?cd_pav_usuario=" +
                //         decryptJWT("codigo")
                // );
                // await Promise.all(
                //     contasUsuario.data.dados.map((item) => {
                //         fatContas.push(item.cd_fat_conta);
                //     })
                // );
                // if (contasUsuario.data.dados.length == 0) {
                //     fatContas = [0];
                // }
                // const request =  "/m1/servicos/alterar_token?login=" +decryptJWT("login") +"&email=" +decryptJWT("email") +"&codigo=" +decryptJWT("codigo")
                //         +"&codigoCliente=" +cdCliente +"&codigoClienteErpAntigo=" +noCdCliente +"&codigoClienteTexto=" +noCdCliente +"&nomeDeUsuario=" 
                //         +decryptJWT("nomeDeUsuario") +"&perfil=" +decryptJWT("perfil") +"&cdSmsContaUsuario=" +decryptJWT("cdSmsContaUsuario") +"&id=" 
                //         +decryptJWT("id") +"&fatContas=" + fatContas+ "&clienteNivel="+clienteNivel+"&tipoPerfil=" +decryptJWT("tipoPerfil")
                //         +"&clientePrimario=" +decryptJWT("clientePrimario");
                // await api.get(request).then(
                //     function (response) {
                //         localStorage.setItem(
                //             "@veye-Token",
                //             response.data.dados.token
                //         );
                //     }
                // ).catch(function (error) {});

                // localStorage.setItem("codigoClienteErpAntigo",noCdCliente);
                // setRevelarMensagemSucesso(true);
                // //Atualiza as permissoes do usuario
                // await api.get('/m1/servicos/token/atualizarLogin?cd_pav_usuario='+decryptJWT('codigo'))

                // localStorage.setItem('codigoClienteErpAntigo',noCdCliente)
                // setRevelarMensagemSucesso(true)
                await api.post("/m1/alterar/usuario", {
                    dados: {
                        ds_cliente_texto: noCdCliente,
                        cd_pav_cliente: cdCliente,
                    },
                    condicao:{
                        cd_pav_usuario: decryptJWT("codigo"),
                    },
                    painel_virtueyes:true
                })
                
                
                let fatContas = [];
                const contasUsuario = await api.get(
                    "/m1/consultar/fat_conta/contas_usuario?cd_pav_usuario=" +
                        decryptJWT("codigo")
                );
                await Promise.all(
                    contasUsuario.data.dados.map((item) => {
                        fatContas.push(item.cd_fat_conta);
                    })
                );
                if (contasUsuario.data.dados.length == 0) {
                    fatContas = [0];
                }
                
                const request =  "/m1/servicos/alterar_token?login=" +decryptJWT("login") +"&email=" +decryptJWT("email") +"&codigo=" +decryptJWT("codigo")
                    +"&codigoCliente=" +cdCliente +"&codigoClienteErpAntigo=" +noCdCliente +"&codigoClienteTexto=" +noCdCliente +"&nomeDeUsuario=" 
                    +decryptJWT("nomeDeUsuario") +"&perfil=" +decryptJWT("perfil") +"&cdSmsContaUsuario=" +decryptJWT("cdSmsContaUsuario") +"&id=" 
                    +decryptJWT("id") +"&fatContas=" + fatContas+ "&clienteNivel="+clienteNivel+"&tipoPerfil=" +decryptJWT("tipoPerfil")
                    +"&clientePrimario=" +decryptJWT("clientePrimario")+"&clienteOriginal="+decryptJWT("clienteOriginal")+"&cd_sol_perfil="+decryptJWT("cd_sol_perfil");
                await api.get(request)
                    .then(function (response) {
                        localStorage.setItem( "@veye-Token", response.data.dados.token );
                    })
                    .catch(function (error) {});

                await api.get('/m1/servicos/token/atualizarLogin?cd_pav_usuario='+decryptJWT('codigo'))
                .then(async function (response) {
                    if(localStorage.hasOwnProperty("keyOperadora")){
                        localStorage.removeItem("keyOperadora");
                    }
                    if(localStorage.hasOwnProperty("keyStatusContrato")){
                        localStorage.removeItem("keyStatusContrato");
                    }

                    localStorage.removeItem('temLinhasVivo')
                    setRevelarMensagemSucesso(true)
                })
                .catch(function (error) {
                    setMensagemErro(error.response.data.info)
                    setRevelarMensagemErro(true)
                });

                setSpinAtivo(false)
                despacho(mudarDoppleGangerCliente(false))
            }
            handleDoppelgangerCliente()
        }
    }, [revelarDoppelgangerClienteVar]);

    function inserirData(data) {
        for (let i = 0; i < totalItens; i++) {
            if (contador === i) {
                let k = i;
                for (let j = 0; j < data.length; j++) {
                    itensVar[k] = data[j];
                    k++;
                }
            }
            if (i == totalItens - 1) {
                setItens(JSON.parse(JSON.stringify(itensVar)));
            }
        }
    }

    async function contarItens() {
        let arrayItens = [];
        let apiData = [];
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get(
                '/m1/consultar/cliente?contar=true&buscar=' + encodeURIComponent(conteudoBusca) + "&painel_virtueyes=true&separadorLinha="+separadorLinha
            );
        } else {
            const dados = {
                paginaAtual: contador,
                // cliente_relacionamento: clienteRelacionamento, 
                carregarLimit: valorLimite,
                buscar: conteudoBusca,
                painel_virtueyes:true,
                filtros: filtros,
                contar: true,
                separadorLinha: separadorLinha
            };
            apiData = await api.post("/m1/filtrar/cliente", dados);
        }
        totalItens = apiData.data.dados[0].CONTADOR;
        totalPaginas = Math.ceil(totalItens / valorLimite);
        for (var i = 0; i < totalItens; i++) {
            arrayItens.push(i);
        }
        itensVar = arrayItens;
    }

    const itemExpandido = {
        className: "fundo-cor-1",
        renderer: (row, rowIndex) => (
            <div
                className={
                    estadoExpansaoMenu === true
                        ? "expand-row-tabela-clientes-virtueyes-1"
                        : "expand-row-tabela-clientes-virtueyes-2"
                }
            >
                <DetalhamentoCliente
                    noCdCliente={row.n0_codigo_cliente}
                    cdCliente={row.cd_pav_cliente}
                    logradourocliente={row.logradouro}
                    bairrocliente={row.bairro}
                    ufcliente={row.uf}
                    cidadecliente={row.cidade}
                    cepcliente={row.cep}
                    vendedorcliente={row.ds_nome}
                    cpfVendedorCliente={row.ds_cpf}
                    emailVendedorCliente={row.ds_email}
                    telefoneVendedorCliente={row.ds_telefone}
                    // handleAtualizar={props.handleAtualizar}
                />
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="tela-background"></div>
        ),
        expandColumnRenderer: (rowKey) => {
            return (
                <Button
                    className="botao-expandir-row"
                    onClick={(e) => GirarSetaDropdown(rowKey.rowKey)}
                >
                    <Image
                        id={rowKey.rowKey}
                        className="icone-botao-expandir-row nao-selecionavel"
                        src={setaDropdown()}
                    />
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: "tabela-coluna-primeira-header",
    };

    const colunas = [
        {
            dataField: "n0_codigo_cliente",
            headerClasses: "nao-selecionavel",
            text: "Código",
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined ? cell : -1}
                    </p>
                );
            },
        },
        {
            dataField: "ds_pav_cliente",
            headerClasses: "nao-selecionavel",
            text: "Razão social",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined ? cell : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "ds_nome_fantasia",
            headerClasses: "nao-selecionavel",
            text: "Nome fantasia",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined ? cell : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "vendedor",
            headerClasses: "nao-selecionavel",
            text: "Atendido por",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined ? cell : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "ds_cpf",
            headerClasses: "nao-selecionavel",
            text: "CPF/CNPJ",
            sort: true,
            formatter: (cell, row) => {
                if (cell == null || cell == "" || cell == undefined || cell == 'undefined') {
                    return (
                        <p className="fonte-cor-1 label-tabela-1">
                            {row.ds_cnpj !== "" || row.ds_cnpj !== null || row.ds_cnpj !== undefined || row.ds_cnpj !== 'undefined' ? row.ds_cnpj : '-'}
                        </p>
                    );
                } else {
                    return (
                        <p className="fonte-cor-1 label-tabela-1">
                            {cell !== "" || cell !== null || cell !== undefined ? cell : '-'}
                        </p>
                    );
                }
            },
        },
        {
            dataField: "ds_telefone",
            headerClasses: "nao-selecionavel",
            text: "Telefone",
            sort: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined ? cell : "-"}
                    </p>
                );
            },
        },
        {
            dataField: 'ds_cliente_origem',
            headerClasses: 'nao-selecionavel',
            text: 'Cliente pertencente',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ultimo_acesso',
            headerClasses: 'nao-selecionavel',
            text: 'Último acesso',
            sort: true,
            formatter: (cell, row) => {
                if (cell) {
                    cell = cell.replace("T", " ").split(".");
                    if (cell.length > 0) {
                        cell = cell[0];
                    }
                }
                return formatarData(cell);
            },
        },
        {
            dataField: "logradouro",
            headerClasses: "nao-selecionavel",
            text: "Logradouro",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "bairro",
            headerClasses: "nao-selecionavel",
            text: "Bairro",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "uf",
            headerClasses: "nao-selecionavel",
            text: "UF",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "cidade",
            headerClasses: "nao-selecionavel",
            text: "Cidade",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "cep",
            headerClasses: "nao-selecionavel",
            text: "CEP",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "vendedor",
            headerClasses: "nao-selecionavel",
            text: "Responsável",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            headerClasses: "nao-selecionavel",
            dataField: "vendedor_cpf",
            text: "CPF do responsável",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            dataField: "vendedor_email",
            text: "E-mail do responsável",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        },
        {
            headerClasses: "nao-selecionavel",
            dataField: "vendedor_telefone",
            text: "Telefone do responsável",
            hidden: true,
            formatter: (cell, row) => {
                return (
                    <p className="fonte-cor-1 label-tabela-1">
                        {cell !== "" || cell !== null || cell !== undefined
                            ? cell
                            : "-"}
                    </p>
                );
            },
        }
    ];

    const selecaoLinhas = {
        mode: "radio",
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                handleSelecionarCliente(
                    row.cd_pav_cliente,
                    row.ds_pav_cliente,
                    row.logradouro,
                    row.bairro,
                    row.uf,
                    row.cidade,
                    row.cep,
                    row.vendedor,
                    row.vendedor_cpf,
                    row.vendedor_email,
                    row.vendedor_telefone,
                    row.n0_codigo_cliente,
                    row.n0_nivel
                );
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE" />
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            );
        },
    };

    function handleSelecionarCliente(
        codigo,
        cliente,
        logradouro,
        bairro,
        uf,
        cidade,
        cep,
        vendedor,
        vendedor_cpf,
        vendedor_email,
        vendedor_telefone,
        n0_codigo_cliente,
        n0_nivel
    ) {
        setNoCdCliente(n0_codigo_cliente);
        setCdCliente(codigo);
        setDsCliente(cliente);
        setLogradouroCliente(logradouro);
        setBairroCliente(bairro);
        setUfCliente(uf);
        setCidadeCliente(cidade);
        setCepCliente(cep);
        setVendedorCliente(vendedor);
        setCpfVendedorCliente(vendedor_cpf);
        setEmailVendedorCliente(vendedor_email);
        setTelefoneVendedorCliente(vendedor_telefone);
        setClienteNivel(n0_nivel)
        despacho(desabilitarAcoes(false));
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setNoCdCliente(row.n0_codigo_cliente);
            setCdCliente(row.codigo);
            setDsCliente(row.cliente);
            setLogradouroCliente(row.logradouro);
            setBairroCliente(row.bairro);
            setUfCliente(row.uf);
            setCidadeCliente(row.cidade);
            setCepCliente(row.cep);
            setVendedorCliente(row.vendedor);
            setCpfVendedorCliente(row.vendedor_cpf);
            setEmailVendedorCliente(row.vendedor_email);
            setTelefoneVendedorCliente(row.vendedor_telefone);
            setClienteNivel(row.n0_nivel)
        },
    };

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: "Primeira",
        lastPageTitle: "Última",
        nextPageTitle: "Proxima",
        prePageTitle: "Anterior",
        custom: true,
        onPageChange: (page) => {
            setPaginaAtual(page);
        },
    };

    function restaurarConfigsIniciaisPagina() {
        totalPaginas = 0;
        contador = 0;
        controladorPagina = 1;
        totalItens = 0;
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1);
    }

    function handleMensagemSucesso(confirmacao) {
        if (revelarMensagemSucesso === false) {
            setRevelarMensagemSucesso(true);
        } else {
            setRevelarMensagemSucesso(false);
            despacho(revelarDoppelgangerCliente(false));
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar));
            if (confirmacao === true) {
                window.location.reload()
                // props.handleAtualizar()
            }else{
                window.location.reload()
                // props.handleAtualizar()
            }
        }
    }

    function handleMensagemErro(confirmacao) {
        if (revelarMensagemErro === false) {
            setRevelarMensagemErro(true);
        } else {
            setRevelarMensagemErro(false);
            if (confirmacao === true) {
                //ALGUMA FUNÇÃO
            }
        }
    }

    return (
        <div className="container-tela">
            {spinAtivo && (
                <div className="componente-spinner-overlay">
                    <div className="componente-spinner-container">
                        <SpinerVeye />
                    </div>
                </div>
            )}
            {revelarMensagemSucesso && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O cliente " + dsCliente + ","}
                            mensagemParagrafo2={
                                "cód. " + noCdCliente + " foi carregado."
                            }
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            )}
            {revelarMensagemErro && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={
                                "Ocorreu um erro. Tente novamente mais tarde."
                            }
                            mensagemParagrafo2={
                                "Caso o erro persista, entre em contato com o Suporte."
                            }
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            )}
            {revelarCadastroCliente === true && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroCliente 
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            )}
            {revelarHistoricoAcessos === true && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <HistoricoAcessos codigo_cliente={noCdCliente} />
                    </div>
                </div>
            )}
            {revelarCadastrarUsuario === true && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroUsuario
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}                        
                        />
                    </div>
                </div>
            )}
            {revelarAdicionarLogin === true && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <AdicionarLogin renderizar={renderizar} setRenderizar={setRenderizar} codigo_cliente={cdCliente} />
                    </div>
                </div>
            )}
            {revelarConsultarSimcard === true && (
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <ConsultarSimcard />
                    </div>
                </div>
            )}
            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_cliente' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={itemExpandido}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication="Nenhum item encontrado"
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando
                <span className="label-tabela-itens-exibidos">
                    {contador + 1}
                </span>
                -
                <span className="label-tabela-itens-exibidos">
                    {contador + valorLimite <= totalItens
                        ? contador + valorLimite
                        : totalItens}
                </span>
                de
                <span className="label-tabela-itens-exibidos">
                    {totalItens}
                </span>
            </text>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh,
    };
}
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));